import React from "react";
import styled from "styled-components";
import TwitterButton from "../assets/Buttons/TwitterButton.png";
import DiscordButton from "../assets/Buttons/DiscordButton.png";
import InstaButton from "../assets/Buttons/InstaButton.png";
import { Row, Col } from "reactstrap";

function Header() {
  return (
    <HeaderRow>
      <Col>
        <div style={{ textAlign: "center" }}>
          {/* <Button href="https://www.instagram.com/spacecowsnft/">
            <Image alt="Instagram" src={InstaButton} />
          </Button> */}
          <Button href="https://discord.gg/jR97AQdQ">
            <Image alt="Discord" src={DiscordButton} />
          </Button>
          <Button href="https://twitter.com/SpaceCowsNFT">
            <Image alt="Twitter" src={TwitterButton} />
          </Button>
        </div>
      </Col>
    </HeaderRow>
  );
}
const Image = styled.img`
  height: 1.5em;
  background-color: black;
  -webkit-box-shadow: 0px 0px 7px 7px #000;
  box-shadow: 0px 0px 7px 7px #000;
  &:hover {
    transform: scale(1.1) perspective(10px);
  }
  &:active {
    transform: scale(0.9) perspective(10px);
  }
`;

const HeaderRow = styled(Row)`
  paddingtop: 20px;
`;

const Button = styled.a`
  padding-left: 15px;
`;

export default Header;
