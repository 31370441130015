import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";

function Letter(props) {
  return (
    <Container>
      {props.emptyLetter ?
       <img  src={props.letter}
        style={{ height: "calc(10px + 9vmin)", padding: 5 }}/> 
        :  <Image
        src={props.letter}
        style={{ height: "calc(10px + 9vmin)", padding: 5 }}
      />}
    </Container>
  );
}

const float = keyframes`
 from { transform: translate(0, 0px); }
 65% { transform: translate(0, 15px); }
 to% { transform: translate(0, -0px); }
`;

const Image = styled.img`
  background-color: black;
  -webkit-box-shadow: 0px 0px 5px 5px #000;
  box-shadow: 0px 0px 5px 5px #000;
  &:hover {
    transform: scale(1.05) perspective(1px);
  }
`;

const Container = styled.div`
  animation-name: ${float};
  animation-duration: 7s;
  animation-iteration-count: infinite;
`;

export default Letter;
