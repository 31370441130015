import React from "react";
import styled from "styled-components";
import "./styles.css";
import { keyframes } from "styled-components";

import { Col, Row } from "react-bootstrap";
import Letter from "./Letter";

import LetterS from "../assets/TitleFont/Letter-S.png";
import LetterP from "../assets/TitleFont/Letter-P.png";
import LetterA from "../assets/TitleFont/Letter-A.png";
import LetterC from "../assets/TitleFont/Letter-C.png";
import LetterE from "../assets/TitleFont/Letter-E.png";
import LetterO from "../assets/TitleFont/Letter-O.png";
import LetterW from "../assets/TitleFont/Letter-W.png";
import Empty from "../assets/TitleFont/Empty.png";

function MainTitle(props) {
  return (
    <div>
      <Container>
        <Letter letter={LetterS} />
        <Letter letter={LetterP} />
        <Letter letter={LetterA} />
        <Letter letter={LetterC} />
        <Letter letter={LetterE} />
      </Container>
      <Container>
        <Letter letter={Empty} emptyLetter/>
        <Letter letter={Empty} emptyLetter/>
        <Letter letter={LetterC} />
        <Letter letter={LetterO} />
        <Letter letter={LetterW} />
        <Letter letter={LetterS} />
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default MainTitle;
