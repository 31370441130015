import React from "react";
import styled from "styled-components";
import BuyButtonImg from "../assets/Buttons/BuyButton.png";

function BuyButton() {
  return (
    <a href="https://opensea.io/collection/space-cows">
      <Image alt="Buy" src={BuyButtonImg} />
    </a>
  );
}
const Image = styled.img`
  height: 2em;
  background-color: black;
  -webkit-box-shadow: 0px 0px 7px 7px #000;
  box-shadow: 0px 0px 7px 7px #000;
  &:hover {
    transform: scale(1.1) perspective(10px);
  }
  &:active {
    transform: scale(0.9) perspective(10px);
  }
`;

export default BuyButton;
