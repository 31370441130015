import React from "react";
import styled from "styled-components";
import "./styles.css";
import { keyframes } from "styled-components";

import CowGif from "../assets/SpaceCows/CowGifTV.gif";

function SpaceCowDisplay(props) {
  return (
    <div>
      <Container>
        <Image
          key={CowGif}
          src={CowGif}
          style={{ width: "50%", minWidth: 150 }}
        />
      </Container>
    </div>
  );
}
const float = keyframes`
 from { transform: translate(0, 0px); }
 65% { transform: translate(0, 15px); }
 to% { transform: translate(0, -0px); }
`;

const Image = styled.img`
  -webkit-box-shadow: 0px 0px 7px 7px #000;
  box-shadow: 0px 0px 7px 7px #000;
  &:hover {
    transform: scale(1.05) perspective(10px);
  }
  &:active {
    transform: scale(1) perspective(10px);
  }
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
  // margin-top: 30%;
  padding-bottom: 20px;
  padding-top: 30px;
  // animation-name: ${float};
  // animation-duration: 7s;
  // animation-iteration-count: infinite;
`;

export default SpaceCowDisplay;
