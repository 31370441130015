import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import MainTitle from "./components/MainTitle";
import SpaceCowDisplay from "./components/SpaceCowDisplay";
import ParticleConfig from "./config/particle-config-sun";
import ParticleConfigEarth from "./config/particle-config-earh";
import ParticleConfigStar from "./config/particle-config-star";
import ParticleConfigRed from "./config/particle-config-red";
import ParticleConfigBig from "./config/particle-config-bigStar";
import ParticleConfigPurple from "./config/particle-config-purple";
import BuyButton from "./components/BuyButton";
import ParticleBackground from "./ParticleBackground";
import Header from "./components/Header";

function App() {
  return (
    <div className="App">
      <div>
        <div id="particles-js">
          <StyledParticleBack particleConfig={ParticleConfig} />
          <StyledParticleBack particleConfig={ParticleConfigBig} />
          <StyledParticleBack particleConfig={ParticleConfigStar} />
          <StyledParticleBack particleConfig={ParticleConfigEarth} />
          <StyledParticleBack particleConfig={ParticleConfigRed} />
          <StyledParticleBack particleConfig={ParticleConfigPurple} />
        </div>
        <Container className="App">
          <Row style={{ paddingTop: "17%" }}>
            <Col>
              <MainTitle />
              <br />
              <Header />
              <Text
                style={{
                  marginRight: 40,
                  marginLeft: 40,
                  marginTop: 20,
                }}
              >
                Space Cows is an NFT collection that consists of 4000
                programatically generated unique digital collectables.
                <b> 150/4000</b> minted.
                <br />
              </Text>
              <ContainerCenter>
                <BuyButton />
              </ContainerCenter>
            </Col>
            <Col className="center" style={{ minWidth: "45%" }}>
              <SpaceCowDisplay />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

const Text = styled.p`
  font-size: calc(10px + 0.7vmin);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Lo-Res", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffff8d;
  text-align: center;
  background-color: black;
  -webkit-box-shadow: 0px 0px 5px 5px #000;
  box-shadow: 0px 0px 5px 5px #000;
`;

const ContainerCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StyledParticleBack = styled(ParticleBackground)`
  z-index: -1;
`;

export default App;
