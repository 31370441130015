import React from "react";
import Particles from "react-particles-js";
import styled from "styled-components";

export default function ParticleBackground(props) {
  return (
    <Particles
      params={props.particleConfig}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: -1,
      }}
    ></Particles>
  );
}
